import { useMemo } from "react";

import { ChordAnalyticsContext } from "@/hooks/useChord";
import { createChordClient } from "@/lib/chord";

export const ChordProvider = ({ children }: { children: React.ReactNode }) => {
  const currency = "USD";
  const locale = "en-US";
  const chord = createChordClient(currency, locale);
  const value = useMemo(() => chord, [chord]);

  return <ChordAnalyticsContext.Provider value={value}>{children}</ChordAnalyticsContext.Provider>;
};

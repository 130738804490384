import { createContext, useContext } from "react";

import type { CurrentUser } from "@/types/user";

type UserContextType = {
  user: CurrentUser | null;
  setUser: (user: CurrentUser | null) => void;
};

export const UserContext = createContext<UserContextType | undefined>(undefined);

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}

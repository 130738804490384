import { useState } from "react";

import { UserContext } from "@/hooks/useUser";

import type { CurrentUser } from "@/types/user";
import type { ReactNode } from "react";

export function UserProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<CurrentUser | null>(null);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
}

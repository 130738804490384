import productFormatter from "@/lib/chord/formatters/product";

import type { AnalyticsLineItemInput } from "@/lib/chord/analytics-types";
import type { LineItemFormatter } from "@chordcommerce/analytics";

const lineItemFormatter: LineItemFormatter<AnalyticsLineItemInput> = ({ lineItem }) => ({
  ...productFormatter({ product: lineItem }),
  quantity: lineItem.quantity,
});

export default lineItemFormatter;

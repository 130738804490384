import { ChordAnalytics } from "@chordcommerce/analytics";

import { analytics } from "@/lib/segment";
import cartFormatter from "@/lib/chord/formatters/cart";
import checkoutFormatter from "@/lib/chord/formatters/checkout";
import lineItemFormatter from "@/lib/chord/formatters/line-item-formatter";
import productFormatter from "@/lib/chord/formatters/product";

import type { AnalyticsChordInputs } from "@/lib/chord/analytics-types";
import type { AnyAnalytics, ChordAnalyticsOptions } from "@chordcommerce/analytics";

export const createChordOptions = (currency: string, locale: string): ChordAnalyticsOptions => ({
  cdp: analytics as unknown as AnyAnalytics,
  formatters: {
    objects: {
      cart: cartFormatter,
      checkout: checkoutFormatter,
      lineItem: lineItemFormatter,
      product: productFormatter,
    },
  },
  metadata: {
    i18n: {
      currency,
      locale,
    },
    ownership: {
      omsId: import.meta.env.VITE_PUBLIC_CHORD_OMS_ID!,
      storeId: import.meta.env.VITE_PUBLIC_CHORD_STORE_ID!,
      tenantId: import.meta.env.VITE_PUBLIC_CHORD_TENANT_ID!,
    },
    platform: {
      name: "chord",
      type: "web",
    },
    store: {
      domain: "dreamvault.co",
    },
  },
});

export const createChordClient = (currency: string, locale: string): ChordAnalytics<AnalyticsChordInputs> => {
  const options = createChordOptions(currency, locale);
  return new ChordAnalytics<AnalyticsChordInputs>(options);
};

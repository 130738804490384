import "./index.css";

import Hotjar from "@hotjar/browser";
import { Toaster } from "react-hot-toast";

import { ChordProvider } from "@/contexts/chord-context";
import { initKlaviyo } from "@/utils/klaviyo";
import { UserProvider } from "@/contexts/user-context";

// Initialize third-party scripts only on the client side
if (typeof window !== "undefined") {
  const siteId = 5301486;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
  initKlaviyo();
}

export default function LayoutDefault({ children }: { children: React.ReactNode }) {
  return (
    <UserProvider>
      <ChordProvider>
        <div className="bg-gradient-to-b from-base-100 to-base-100">{children}</div>
        <Toaster />
      </ChordProvider>
    </UserProvider>
  );
}
